/*###########################################################################
// Logo Bar
//#########################################################################*/

import Swiper from 'swiper'
import { Autoplay } from 'swiper/modules'

const sliderLogoBar = {
	config: {
		sliderLogoBar: '.sliderLogoBar',
		sliderLogo: ".sliderLogoBar__logo"
	},

	elements: {
		sliderLogoBar: null
	},

	init() {
		const self = this

		self.elements.sliderLogoBar = document.querySelectorAll(self.config.sliderLogoBar)

		if (self.elements.sliderLogoBar !== null) {
      for (let i = 0; i < self.elements.sliderLogoBar.length; i++) {
        const slider = self.elements.sliderLogoBar[i]

        self.handleSliderLogoBar(slider)
      }
    }
	},

	handleSliderLogoBar(slider) {

		const self = this

    const sliderInstance = new Swiper(slider, {
      modules: [Autoplay],
      spaceBetween: 10,
      centeredSlides: false,
      speed: 3000,
      autoplay: {
        delay: 1
      },
      loop: true,
      slidesPerView: 5,
      allowTouchMove: true,
      disableOnInteraction: true,
      breakpoints: {
        // when window width is >= 320px
        // when window width is >= 480px
        480: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 2,
          spaceBetween: 24
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 32
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 32
        },
        1280: {
          speed: 6000,
          slidesPerView: 5,
          spaceBetween: 40
        },
        1440: {
          slidesPerView: 5,
          spaceBetween: 40
        }
      }
    })

	}
}

window.addEventListener('load', function () {
  sliderLogoBar.init()
})


/*###########################################################################
// Cursor
//#########################################################################*/

const cursor = {
	config: {
		cursor: '.cursor'
	},

	elements: {
		cursors: null
	},

	init() {
		const self = this

		self.elements.cursors = document.querySelectorAll(self.config.cursor)

		if (self.elements.cursors !== null) {
			for (let i = 0; i < self.elements.cursors.length; i++) {
				const cursor = self.elements.cursors[i]

				if (window.innerWidth >= 1024) {
					self.handleCursor(cursor)
				}
			}
		}
	},

	handleCursor(cursor) {
		const self = this

		const mouseArea = document.querySelector(cursor.getAttribute('data-mouse-area'))

		if (!mouseArea) {
			return
		}

		document.addEventListener('mousemove', function (e) {
    	if (mouseArea.contains(e.target)) {
				let y = e.pageY - (cursor.clientHeight * 1.4)
				let x = e.pageX - (cursor.clientWidth / 2)

				cursor.style.top = y + 'px'
				cursor.style.left = x + 'px'
			}
		})

		mouseArea.addEventListener('mousedown', function () {
			cursor.classList.add('dragging')
		})

		mouseArea.addEventListener('mouseup', function () {
			cursor.classList.remove('dragging')
		})

		mouseArea.addEventListener('mouseenter', function () {
			cursor.classList.add('hover');
		})

		mouseArea.addEventListener('mouseleave', function () {
			cursor.classList.remove('hover');
		})
	}
}

window.addEventListener('load', function () {
	cursor.init()
})

// /*###########################################################################
// // Slider image
// //#########################################################################*/

import Swiper from 'swiper'

const sliderImage = {
  config: {
    sliderImage: '.sliderImage'
  },

  elements: {},

  init() {
    const self = this

    self.elements.sliderImages = document.querySelectorAll(self.config.sliderImage)

    if (self.elements.sliderImages !== null) {
      for (let i = 0; i < self.elements.sliderImages.length; i++) {
        const sliderImage = self.elements.sliderImages[i]

        self.handleSliderImage(sliderImage)
      }
    }
  },

  handleSliderImage(slider) {
    const self = this

		const cursor = document.querySelector('.cursor')

    const swiper = new Swiper(slider, {
      slidesPerView: 1.5,
      spaceBetween: 20,
      loop: true,
      autoHeight: true,
			centeredSlides: true,
    })

		swiper.on('sliderMove', function (e) {
			let y = e.touches.currentY - (cursor.clientHeight * 1.4)
			let x = e.touches.currentX - (cursor.clientWidth / 2)

			cursor.style.top = y + 'px'
			cursor.style.left = x + 'px'
		})

		swiper.on('touchStart', function () {
			cursor.classList.add('dragging')
		})

		swiper.on('touchEnd', function () {
			cursor.classList.remove('dragging')
		})
  }
}

window.addEventListener('load', function () {
  sliderImage.init()
})
